
import { IProductsList } from '@/interfaces/products';
import { commitSetCartSidebarShow } from '@/store/main/mutations';
import { dispatchGetInvoiceDetails, dispatchRequestProductPurchase, dispatchToggleProductInCart, dispatchToggleProductInWishlist } from '@/store/products/actions';
import { readInvoiceDetail, readProductsInvoice, readProductsInWishlist } from '@/store/products/getters';
import { commitSetProductsInvoice } from '@/store/products/mutations';
import { getLocalSessionId } from '@/utils';
import { Component, Vue, Prop } from 'vue-property-decorator';

@Component({
  components: {
  },
})
export default class ProductGridCard extends Vue {
  @Prop({required: true}) public product!: IProductsList;
  @Prop({required: false, default: 'col-3'}) public cardClass!: string;
  public loadingProduct: boolean = false;
  public selectedParameterValues: any = {};
  public showAllParam = false;

  public mounted() {
    let selectedParameterValues: any = {};
    for (let i in this.product.properties) {
      selectedParameterValues[this.product.properties[i].id] = this.product.properties[i].values[0].id;
    }
    this.selectedParameterValues = selectedParameterValues;
  }

  public async handleAddToCart() {
    if (!this.selectedVariation) {
      return null;
    }
    // @ts-ignore
    // this.animateElement(`add-to-cart-${this.product.id}`, 'animate__bounceIn');
    this.loadingProduct = true;
    let inCart: any = null;
    if (this.invoiceDetails) {
      for (let i in this.invoiceDetails.items) {
        if (this.invoiceDetails.items[i].object_id === this.selectedVariation.id) {
          inCart = {
            id: this.invoiceDetails.items[i].id,
            quantity: this.invoiceDetails.items[i].quantity,
          };
        }
      }
    }

    if (inCart) {
      await dispatchToggleProductInCart(this.$store, {invoice_item: inCart.id, action: 'add'});
    } else {
      const response = await dispatchRequestProductPurchase(
        this.$store, {
          product_property: this.selectedVariation.id,
          quantity: 1,
          invoice: this.productsInvoice ? this.productsInvoice : null,
          anon_session_id: getLocalSessionId(),
        },
      );
      commitSetProductsInvoice(this.$store, response);
      if (this.productsInvoice) {
        await dispatchGetInvoiceDetails(this.$store, {invoice_id: this.productsInvoice, anon_session_id: getLocalSessionId(),});
      }
      commitSetCartSidebarShow(this.$store, true);
    }
    // @ts-ignore
    this.$toast.success(this.$t('Добавлено в корзину'), {
      duration: 3000, position: 'bottom-right',
    });
    this.loadingProduct = false;
    // @ts-ignore
    // this.animateElement(`added-to-cart-amount-${this.product.id}`, 'animate__jello');
  }

  public async handleRemoveFromCart() {
    if (!this.selectedVariation) {
      return null;
    }
    // @ts-ignore
    // this.animateElement(`remove-to-cart-${this.product.id}`, 'animate__bounceIn');
    this.loadingProduct = true;
    if (this.invoiceDetails) {
      for (let i in this.invoiceDetails.items) {
        if (this.invoiceDetails.items[i]) {
          if (this.invoiceDetails.items[i].object_id === this.selectedVariation.id) {
            await dispatchToggleProductInCart(
              this.$store, {invoice_item: this.invoiceDetails.items[i].id, action: 'remove'},
            );
          }
        }
      }
    }
    this.loadingProduct = false;
    // @ts-ignore
    // this.animateElement(`added-to-cart-amount-${this.product.id}`, 'animate__jello');
  }

  public quantityOfProductInCart() {
    if (this.invoiceDetails && this.selectedVariation) {
      for (let i in this.invoiceDetails.items) {
        if (this.invoiceDetails.items[i]) {
          if (this.invoiceDetails.items[i].object_id === this.selectedVariation.id) {
            return this.invoiceDetails.items[i].quantity;
          }
        }
      }
    }
    return 0;
  }

  public async toggleProductInWishlist() {
    if (this.productsInWishlist.includes(this.product.id)) {
      await dispatchToggleProductInWishlist(
        this.$store, {product_id: this.product.id, action: 'remove', anon_session_id: getLocalSessionId()},
      );
    } else {
      await dispatchToggleProductInWishlist(
        this.$store, {product_id: this.product.id, action: 'add', anon_session_id: getLocalSessionId()},
      );
    }
    this.$emit('wishlist-toggled');
  }

  public async handleSelectParameterValue(parameter, newValue) {
    let newObj: any = {};
    for (const [key, value] of Object.entries(this.selectedParameterValues)) {
      if (Number(parameter) === Number(key)) {
        newObj[key] = newValue;
      } else {
        newObj[key] = value;
      }
    }
    this.selectedParameterValues = newObj;
  }

  public isQuantityOutOfStock(parameter_id, value_id) {
    let selectedValues: any = [];
    // console.log(parameter_id, value_id)
    for (const [key, value] of Object.entries(this.selectedParameterValues)) {
      if (Number(key) !== Number(parameter_id)) {
        selectedValues.push(`${key}:${value}`);
      } else {
        selectedValues.push(`${key}:${value_id}`);
      }
    }
    // console.log(selectedValues)
    for (const [key, value] of Object.entries<any>(this.product.variations)) {
      let matches = 0;
      for (let i in value.parameters) {
        for (let j in selectedValues) {
          if (value.parameters[i] === selectedValues[j]) {
            matches += 1;
          }
        }
      }
      if (matches === value.parameters.length) {
        return value.quantity_count === 0;
      }
      matches = 0;
    }
    return false;

  }

  get selectedVariation(): any {
    let selectedValues: any = [];
    for (const [key, value] of Object.entries(this.selectedParameterValues)) {
      selectedValues.push(`${key}:${value}`);
    }
    for (const [key, value] of Object.entries(this.product.variations)) {
      let matches = 0;
      // @ts-ignore
      for (let i in value.parameters) {
        for (let j in selectedValues) {
          // @ts-ignore
          if (value.parameters[i] === selectedValues[j]) {
            matches += 1;
          }
        }
      }
      // @ts-ignore
      if (matches === value.parameters.length) {
        // console.log(value);
        // console.log(this.selectedParameterValues);
        return value;
      }
      matches = 0;
    }
    return null;
  }

  get invoiceDetails() {
    return readInvoiceDetail(this.$store);
  }

  get productsInvoice() {
    return readProductsInvoice(this.$store);
  }
  get productsInWishlist() {
    return readProductsInWishlist(this.$store);
  }
}
